import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Layout/Home";
import Header from "./components/Layout/Header";
import Timetable from "./components/Timetables/Rendeles/Timetable";
import Private from "./components/Timetables/Rendeles/Private";
import PersonalInfo from "./components/PersonalInfo";
import Collegaue from "./components/Collegaue";
import Mind from "./components/Mind";
import { Laci, Collegaues } from "./assets/data";
import { Minds, actual, actualtext, tanacsadas, summer, nyariIdoszakok, emailForm } from "./assets/data";
import Contact from "./components/Contact";
import Actual from './components/Timetables/Rendeles/Actual';
// import Login from './components/Login';

function App() {
  const [offset, setOffset] = useState(0);
  const [isActual, setIsActual] = useState(false);
  const [isExtraInfo, setIsExtraInfo] = useState(true);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header onScroll={offset} />}>
          <Route index element={isActual ? <Actual actualinfo={actual} actualtext={actualtext} tanacsadas={tanacsadas} summer={summer} nyariIdoszakok={nyariIdoszakok} title="Aktuális" /> : <Timetable title="Rendelési idõ" emailForm="emailForm" />} />
          {/* <Route path="rendeles" element={<Actual actualinfo={actual} actualtext={actualtext} tanacsadas={tanacsadas} summer={summer} nyariIdoszakok={nyariIdoszakok} title="Aktuális" />} /> */}
          <Route path="rendeles" element={<Timetable title="Rendelési idõ" />} />
          <Route path="private" element={<Private />} />
          <Route path="intro" element={<PersonalInfo title="Bemutatkozás" data={Laci} />} />
          <Route path="collegaues" element={<Collegaue title="Munkatársaim" data={Collegaues} />} />
          <Route path="minds" element={<Mind minds={Minds} title='Alkalmi gondolatok' />} />
          <Route path="contacts" element={<Contact title='Kapcsolat' />} />
          {/* <Route path="admin" element={<Login title='Login' />} /> */}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
