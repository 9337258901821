import React from 'react';
import Card from '../components/UI/Card';
import styles from '../components/PersonalInfo.module.css';
import SubHeader from './Layout/SubHeader';
import Image from './UI/Image';

const PersonalInfo = (props) => {
    console.log('props', props);
    const { title, data } = props;

    const block1 = data[0].text1.split('\n');
    const block2 = data[0].text2.split('\n');
    console.log('cc', block2);
    return (
        <Card>
            <SubHeader title={title} />
            <div>
                <div className={styles.shortIntro}>
                    <Image className={styles.image} alt={data[0].name} src={data[0].url1} width='40%'/>
                    <h2>{data[0].name}</h2>
                    {block1.map(x => <p>{x}</p>)}
                </div>
                <div className={styles.long}>
                    {block2.map(x => <p className={styles.long}>{x}</p>)}
                    <Image className={title} alt={data[0].name} src={data[0].url2} width='100%' />
                </div>
            </div>
        </Card>
    );
}

export default PersonalInfo;
